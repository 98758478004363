import { Fragment, useEffect, useState } from "react"
import APIRequest from "../../../../helpers/CreateRequest"
import Button from "../../../../components/atoms/Button"
import { Currency } from "../../../../helpers/InputValueConverter"
import { option } from "../HouseHistoryReport/HouseHistory"
import MultiSelectField from "../../../../components/atoms/forms/SimpleFields/MultiSelectField"
import { house } from "../GrossProfitReport/GrossProfitReport"


type community = {
    houses: house[]
}

type territory = {
    communities: Map<string, community>
}

type region = {
    territories: Map<string, territory>
}

type regionMap = Map<string, region>


type pipelineFilters = {
    Community: string[]
    Year: string[]
    EventType: string[]
    SaleStatus: string[]
    SaleEventStatus: string[]
    StartEventStatus: string[]
    SettlementEventStatus: string[]
}

type pipelineOptions = {
    Community: option[]
    Year: option[]
    EventType: option[]
    SaleStatus: option[]
    SaleEventStatus: option[]
    StartEventStatus: option[]
    SettlementEventStatus: option[]
}

type totals = {
    [key: string]: {
        [key: string]: {
            units: number
            totalSales: number
        }
    }
}

export default function PipelineReport({ ScenarioID, triggerNewData }: { ScenarioID: string, triggerNewData: boolean }) {
    const [housesBackup, setHousesBackup] = useState<house[]>()
    const [regionMap, setRegionMap] = useState<regionMap>()

    const [openRegions, setOpenRegions] = useState<string[]>([])
    const [openTerritories, setOpenTerritories] = useState<string[]>([])
    const [openCommunities, setOpenCommunities] = useState<string[]>([])

    const [totals, setTotals] = useState<{ regionTotals: totals, territoryTotals: totals, communityTotals: totals }>({ regionTotals: {}, territoryTotals: {}, communityTotals: {} })

    const [filters, setFilters] = useState<pipelineFilters>({
        Community: [],
        Year: [],
        EventType: [],
        SaleStatus: [],
        SaleEventStatus: [],
        StartEventStatus: [],
        SettlementEventStatus: []
    })

    const [options, setOptions] = useState<pipelineOptions>({
        Community: [],
        Year: [],
        EventType: [{ name: 'Sale', value: 'Sale' }, { name: 'Start', value: 'Start' }, { name: 'Settlement', value: 'Settlement' }],
        SaleStatus: [],
        SaleEventStatus: [],
        StartEventStatus: [],
        SettlementEventStatus: []
    })

    const handleData = (housesList: house[]) => {
        setOptions(getFilterOptions(housesList))

        const regionMap: regionMap = new Map()
        const houses = filterData(housesList)

        // create map that will hold all houses filtered down by region, to territory, to community, to house 
        houses.forEach(house => {
            // check if region exists
            const region = regionMap.get(house.RegionNameCode)
            if (region) {
                const territory = region.territories.get(house.TerritoryNameCode)
                if (territory) {
                    const community = territory.communities.get(house.CommunityNameCode)
                    if (community) {
                        community.houses.push(house)
                    } else {

                        const newCommunity: community = { houses: [house], }

                        territory.communities.set(house.CommunityNameCode, newCommunity)
                    }
                } else {
                    // update region totals, create the territory and add the community and the house to the map
                    const newCommunity: community = {
                        houses: [house],
                    }
                    const territory: territory = {
                        communities: new Map().set(house.CommunityNameCode, newCommunity),
                    }

                    region.territories.set(house.TerritoryNameCode, territory)
                }
            } else {
                const newCommunity: community = {
                    houses: [house],
                }
                const newTerritory: territory = {
                    communities: new Map().set(house.CommunityNameCode, newCommunity),
                }

                const newRegion: region = {
                    territories: new Map().set(house.TerritoryNameCode, newTerritory),
                }

                regionMap.set(house.RegionNameCode, newRegion)
            }
        })

        setRegionMap(regionMap)
        setTotals(calculateTotals(houses))
    }

    // filter the data based on the selected filters
    const filterData = (houses: house[]) => {
        let filteredHouses = houses

        // remove houses with the same house code in the same community name
        filteredHouses = filteredHouses.filter((house, index, self) => {
            return index === self.findIndex(h => h.HouseCode === house.HouseCode && h.CommunityNameCode === house.CommunityNameCode)
        })

        // filter by filtersState 
        filteredHouses = filteredHouses.filter(house => {
            if (filters.Community.length > 0 && !filters.Community.includes(house.CommunityNameCode)) return false
            if (filters.Year.length > 0 && !filters.Year.includes(new Date(house.SaleEventDate).getFullYear().toString()) && !filters.Year.includes(new Date(house.StartEventDate).getFullYear().toString()) && !filters.Year.includes(new Date(house.SettlementEventDate).getFullYear().toString())) return false
            if (filters.SaleStatus.length > 0 && !filters.SaleStatus.includes(house.SaleStatus)) return false
            if (filters.SaleEventStatus.length > 0 && !filters.SaleEventStatus.includes(house.SaleEventStatus)) return false
            if (filters.StartEventStatus.length > 0 && !filters.StartEventStatus.includes(house.StartEventStatus)) return false
            if (filters.SettlementEventStatus.length > 0 && !filters.SettlementEventStatus.includes(house.SettlementEventStatus)) return false
            return true
        })

        return filteredHouses
    }

    const getFilterOptions = (houses: house[]) => {
        // get the unique values for each filter
        const filters: pipelineOptions = {
            Community: [],
            Year: [],
            EventType: [{ name: 'Sale', value: 'Sale' }, { name: 'Start', value: 'Start' }, { name: 'Settlement', value: 'Settlement' }],
            SaleStatus: [],
            SaleEventStatus: [],
            StartEventStatus: [],
            SettlementEventStatus: []
        }

        houses.forEach(house => {
            const saleYear = new Date(house.SaleEventDate).getFullYear().toString()
            const startYear = new Date(house.StartEventDate).getFullYear().toString()
            const settlementYear = new Date(house.SettlementEventDate).getFullYear().toString()
            if (!filters.Community.some(option => option.value === house.CommunityNameCode)) filters.Community.push({ value: house.CommunityNameCode, name: house.CommunityNameCode })
            if (!filters.Year.some(option => option.value === saleYear)) filters.Year.push({ value: saleYear, name: saleYear })
            if (!filters.Year.some(option => option.value === startYear)) filters.Year.push({ value: startYear, name: startYear })
            if (!filters.Year.some(option => option.value === settlementYear)) filters.Year.push({ value: settlementYear, name: settlementYear })
            // if (!filters.EventType.some(option => option.value === house.EventType)) filters.EventType.push({ value: house.EventType, name: house.EventType })
            if (!filters.SaleStatus.some(option => option.value === house.SaleStatus)) filters.SaleStatus.push({ value: house.SaleStatus, name: house.SaleStatus })
            if (!filters.SaleEventStatus.some(option => option.value === house.SaleEventStatus)) filters.SaleEventStatus.push({ value: house.SaleEventStatus, name: house.SaleEventStatus })
            if (!filters.StartEventStatus.some(option => option.value === house.StartEventStatus)) filters.StartEventStatus.push({ value: house.StartEventStatus, name: house.StartEventStatus })
            if (!filters.SettlementEventStatus.some(option => option.value === house.SettlementEventStatus)) filters.SettlementEventStatus.push({ value: house.SettlementEventStatus, name: house.SettlementEventStatus })
        })

        // order the options
        filters.Community.sort((a, b) => a.value.localeCompare(b.value))
        filters.Year.sort((a, b) => a.value.localeCompare(b.value))
        // filters.EventType.sort((a, b) => a.name.localeCompare(b.name))
        filters.SaleStatus.sort((a, b) => a.name.localeCompare(b.name))
        filters.SaleEventStatus.sort((a, b) => a.name.localeCompare(b.name))
        filters.StartEventStatus.sort((a, b) => a.name.localeCompare(b.name))
        filters.SettlementEventStatus.sort((a, b) => a.name.localeCompare(b.name))

        return filters
    }

    const handleClickedObject = (type: 'region' | 'territory' | 'community', name: string) => {
        if (type === 'region') {
            if (openRegions.includes(name)) {
                setOpenRegions(openRegions.filter(region => region !== name))
            } else {
                setOpenRegions([...openRegions, name])
            }
        } else if (type === 'territory') {
            if (openTerritories.includes(name)) {
                setOpenTerritories(openTerritories.filter(territory => territory !== name))
            } else {
                setOpenTerritories([...openTerritories, name])
            }
        } else if (type === 'community') {
            if (openCommunities.includes(name)) {
                setOpenCommunities(openCommunities.filter(community => community !== name))
            } else {
                setOpenCommunities([...openCommunities, name])
            }
        }
    }

    const calculateTotals = (houses: house[]) => {
        let regionTotals: totals = {}
        let territoryTotals: totals = {}
        let communityTotals: totals = {}

        const updateTotals = (totals: any, key: string, yearType: string, year: number, house: house) => {
            if (!totals[key]) {
                totals[key] = {}
            }
            if (!totals[key][`${yearType}${year}`]) {
                totals[key][`${yearType}${year}`] = {
                    units: 0,
                    totalSales: 0,
                }
            }
            totals[key][`${yearType}${year}`].units++
            totals[key][`${yearType}${year}`].totalSales += house.TotalSalesPriceAmount
        }

        for (let i = 0; i < houses.length; i++) {
            const house = houses[i]
            const saleYear = new Date(house.SaleEventDate).getFullYear()
            const region = house.RegionNameCode
            const territory = house.TerritoryNameCode
            const community = house.CommunityNameCode

            updateTotals(regionTotals, region, 'Sale', saleYear, house)
            updateTotals(territoryTotals, territory, 'Sale', saleYear, house)
            updateTotals(communityTotals, community, 'Sale', saleYear, house)

            if (house.StartEventDate) {
                const startYear = new Date(house.StartEventDate).getFullYear()
                updateTotals(regionTotals, region, 'Start', startYear, house)
                updateTotals(territoryTotals, territory, 'Start', startYear, house)
                updateTotals(communityTotals, community, 'Start', startYear, house)
            }

            if (house.SettlementEventDate) {
                const settlementYear = new Date(house.SettlementEventDate).getFullYear()
                updateTotals(regionTotals, region, 'Settlement', settlementYear, house)
                updateTotals(territoryTotals, territory, 'Settlement', settlementYear, house)
                updateTotals(communityTotals, community, 'Settlement', settlementYear, house)
            }
        }
        return { regionTotals: regionTotals, territoryTotals: territoryTotals, communityTotals: communityTotals }
    }

    const handleClear = () => {
        setFilters({
            Community: [],
            Year: [],
            EventType: [],
            SaleStatus: [],
            SaleEventStatus: [],
            StartEventStatus: [],
            SettlementEventStatus: []
        })
    }

    const getData = async () => {
        try {
            const result = await new APIRequest(`/scenariosSalesForecastEvents/grossProfit`, 'POST', null, { scenarioID: ScenarioID }).GenerateRequest()
            if (result.status === 200) {
                const body = await result.json()
                if (body.status === 200) {
                    setHousesBackup(body.recordset)
                    handleData(body.recordset)
                } else throw body.message
            } else throw result.statusText
        } catch (err) {
            console.log(err)
        }
    }

    //call the API to get the data for the selected scenario
    useEffect(() => {
        if (ScenarioID) getData()
    }, [ScenarioID, triggerNewData])

    // filter data when filters change
    useEffect(() => {
        if (housesBackup) handleData(housesBackup)
    }, [filters])

    return (
        <div className="flex flex-col w-full  h-full gap-y-2 ">

            <div className="flex min-w-full h-min gap-x-6 gap-y-3  py-3 items-center max-w-[1200px] bep-shadow-xl rounded-lg px-4 " >
                <div className="flex flex-wrap gap-x-4 gap-y-3 w-max">
                    <span className="headline-large">Pipeline</span>
                    <MultiSelectField name="Community" label="Community" setValue={setFilters} size="md" options={options.Community} selected={filters?.Community || []} />
                    <MultiSelectField name="Year" label="Year" setValue={setFilters} size="sm" options={options.Year} selected={filters?.Year || []} />
                    <MultiSelectField name="EventType" label="Event Type" setValue={setFilters} size="sm" options={options.EventType} selected={filters?.EventType || []} />
                    <MultiSelectField name="SaleStatus" label="Sale Status" setValue={setFilters} size="sm" options={options.SaleStatus} selected={filters?.SaleStatus || []} />
                    <MultiSelectField name="SaleEventStatus" label="Sale Event Status" setValue={setFilters} size="sm" options={options.SaleEventStatus} selected={filters?.SaleEventStatus || []} />
                    <MultiSelectField name="StartEventStatus" label="Start Event Status" setValue={setFilters} size="sm" options={options.StartEventStatus} selected={filters?.StartEventStatus || []} />
                    <MultiSelectField name="SettlementEventStatus" label="Settlement Event Status" setValue={setFilters} size="sm" options={options.SettlementEventStatus} selected={filters?.SettlementEventStatus || []} />
                </div>

                <div className="flex m-auto mr-0 gap-x-2">
                    <Button label="Clear" styleType="tonal" type="button" action={handleClear} />
                </div>
            </div>

            <div className={`overflow-auto max-w-full  bep-shadow-xl rounded-lg ${regionMap ? 'w-max' : 'w-full'} border-2 mx-auto`}>
                <table id="PipelineTable" className=" text-nowrap h-full   border-collapse ">
                    <thead className="sticky top-0 z-10 bg-gray-50 ">

                        <tr>
                            <th className="sticky left-0 z-10 bg-gray-50"></th>
                            {
                                (filters.Year.length > 0 ? filters : options).Year.map(year => {
                                    let tempFilters = (filters.EventType.length > 0 ? filters : options).EventType
                                    const tempYear = parseInt(typeof year === 'string' ? year : year.value)

                                    return (
                                        <th colSpan={tempFilters.length * 3} className=" p-2 text-left sticky top-0" key={'tableHeadYear' + tempYear}>{tempYear}</th>
                                    )
                                })
                            }
                        </tr>

                        <tr>
                            <th className="sticky left-0 z-10  bg-gray-50"></th>
                            {(filters.Year.length > 0 ? filters : options).Year.map(year => {
                                const tempYear = parseInt(typeof year === 'string' ? year : year.value)
                                let tempFilters = (filters.EventType.length > 0 ? filters : options).EventType

                                return tempFilters.map(event => {
                                    let tempEvent = typeof event === 'string' ? event : event.value
                                    return <th colSpan={3} key={'TableHeadEvent' + tempYear + tempEvent} className=" p-2 text-left">{tempEvent}</th>
                                })
                            })
                            }
                        </tr>

                        <tr>
                            <th className="sticky left-0 z-10 bg-gray-50"></th>
                            {(filters.Year.length > 0 ? filters : options).Year.map(year => {
                                let tempYear = parseInt(typeof year === 'string' ? year : year.value)

                                let tempFilters = (filters.EventType.length > 0 ? filters : options).EventType

                                return (
                                    tempFilters.map(event => {
                                        let tempEvent = typeof event === 'string' ? event : event.value

                                        return (
                                            <Fragment key={'tableHeadDataHeaders' + tempYear.toString() + tempEvent}>
                                                <th className="p-2">Units</th>
                                                <th className="p-2">Sale Status</th>
                                                <th className="p-2">Total Sales</th>
                                            </Fragment>)
                                    })
                                )
                            })
                            }
                        </tr>
                    </thead>

                    <tbody>
                        {
                            regionMap ?

                                [...regionMap.keys()].map((region, regIndex) => {

                                    return <Fragment key={'region' + region + regIndex}>
                                        <tr className="even:bg-gray-200  odd:bg-white">
                                            <td className="hover:bg-blue-100  p-1 cursor-pointer text-left font-bold sticky left-0 bg-inherit" onClick={() => handleClickedObject('region', region)}>{openRegions.includes(region) ? '-' : '+'} {region}</td>

                                            {
                                                (filters.Year.length > 0 ? filters : options).Year.map(year => {
                                                    let tempFilters = (filters.EventType.length > 0 ? filters : options).EventType
                                                    let tempYear = parseInt(typeof year === 'string' ? year : year.value)

                                                    return tempFilters.map(event => {
                                                        let tempEvent = typeof event === 'string' ? event : event.value
                                                        let regionTotals = totals.regionTotals[region][`${tempEvent}${tempYear}`] || {}

                                                        return <Fragment key={'regionData' + region + tempYear + tempEvent}>
                                                            <td className="table-cell">{regionTotals.units}</td>
                                                            <td className="table-cell"></td>
                                                            <td className="table-cell">{Currency(regionTotals.totalSales)}</td>
                                                        </Fragment>
                                                    })
                                                })
                                            }
                                        </tr>

                                        {
                                            openRegions.includes(region) && [...(regionMap.get(region)?.territories || new Map()).keys()].map((territory, terIndex) => {

                                                return <Fragment key={'territory' + territory + region}>
                                                    <tr className="even:bg-gray-200 odd:bg-white">
                                                        <td className="hover:bg-blue-100 p-1 pl-4 cursor-pointer text-left font-bold sticky left-0 bg-inherit" onClick={() => handleClickedObject('territory', territory)}>{openTerritories.includes(territory) ? '-' : '+'} {territory}</td>

                                                        {
                                                            (filters.Year.length > 0 ? filters : options).Year.map(year => {
                                                                let tempYear = parseInt(typeof year === 'string' ? year : year.value)
                                                                let tempFilters = (filters.EventType.length > 0 ? filters : options).EventType

                                                                return tempFilters.map(event => {
                                                                    let tempEvent = typeof event === 'string' ? event : event.value
                                                                    let territoryTotals = totals.territoryTotals[territory][`${tempEvent}${tempYear}`] || {}
                                                                    return <Fragment key={"territoryData" + region + territory + tempYear + tempEvent}>
                                                                        <td className="table-cell">{territoryTotals.units}</td>
                                                                        <td className="table-cell"></td>
                                                                        <td className="table-cell">{Currency(territoryTotals.totalSales)}</td>
                                                                    </Fragment>
                                                                })
                                                            })
                                                        }
                                                    </tr>

                                                    {
                                                        openTerritories.includes(territory) && [...(regionMap.get(region)?.territories?.get(territory)?.communities || new Map()).keys()].map(community => {

                                                            return <Fragment key={'community' + community + territory}>
                                                                <tr className="even:bg-gray-200 odd:bg-white">
                                                                    <td className="hover:bg-blue-100 p-1 pl-8 cursor-pointer text-left font-bold sticky left-0 bg-inherit" onClick={() => handleClickedObject('community', community)}>{openCommunities.includes(community) ? '-' : '+'} {community}</td>
                                                                    {
                                                                        (filters.Year.length > 0 ? filters : options).Year.map(year => {
                                                                            let tempYear = parseInt(typeof year === 'string' ? year : year.value)
                                                                            let tempFilters = (filters.EventType.length > 0 ? filters : options).EventType

                                                                            return tempFilters.map(event => {
                                                                                let tempEvent = typeof event === 'string' ? event : event.value
                                                                                let communityTotals = totals.communityTotals[community][`${tempEvent}${tempYear}`] || {}
                                                                                return <Fragment key={'communityData' + region + territory + community + tempYear + tempEvent}>
                                                                                    <td className="table-cell">{communityTotals.units}</td>
                                                                                    <td className="table-cell"></td>
                                                                                    <td className="table-cell">{Currency(communityTotals.totalSales)}</td>
                                                                                </Fragment>
                                                                            })
                                                                        })
                                                                    }
                                                                </tr>

                                                                {
                                                                    openCommunities.includes(community) && (regionMap.get(region)?.territories?.get(territory)?.communities.get(community)?.houses || []).map(house => (
                                                                        <HouseRow key={'house' + region + territory + community + house.HouseCode} house={house} filters={filters} options={options} region={region} territory={territory} community={community} />
                                                                    ))
                                                                }
                                                            </Fragment>
                                                        })
                                                    }
                                                </Fragment>
                                            })
                                        }
                                    </Fragment>
                                })

                                :
                                <tr className="text-center font-bold h-20 m-auto">
                                    <td></td>
                                    <td className="w-full text-center">Please select a scenario</td>
                                    <td></td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

type HouseRowProps = {
    house: house;
    filters: pipelineFilters;
    options: pipelineOptions;
    region: string;
    territory: string;
    community: string;
};

function HouseRow({ house, filters, options, region, territory, community }: HouseRowProps) {

    return (
        <tr className="even:bg-gray-200 odd:bg-white">
            <td className="hover:bg-blue-100 pl-10 text-left sticky left-0 bg-inherit">{house.HouseCode}</td>
            {
                (filters.Year.length > 0 ? filters : options).Year.map(year => {
                    let tempYear = parseInt(typeof year === 'string' ? year : year.value)
                    let tempFilters = (filters.EventType.length > 0 ? filters : options).EventType

                    const validSale = new Date(house.SaleEventDate).getFullYear() === tempYear
                    const validStart = new Date(house.StartEventDate).getFullYear() === tempYear
                    const validSettlement = new Date(house.SettlementEventDate).getFullYear() === tempYear

                    return tempFilters.map(event => {
                        let tempEvent = typeof event === 'string' ? event : event.value
                        const validHouse = (tempEvent === 'Sale' ? validSale : tempEvent === 'Start' ? validStart : tempEvent === 'Settlement' ? validSettlement : false)

                        return <Fragment key={'houseData' + region + territory + community + house.HouseCode + tempYear + tempEvent}>
                            <td className="table-cell"></td>
                            <td className="table-cell">{validHouse ? house.SaleStatus : ''}</td>
                            <td className="table-cell">{validHouse ? Currency(house.TotalSalesPriceAmount) : ''}</td>
                        </Fragment>
                    })
                })}
        </tr>
    )
}